import React from 'react';

export default function MentionsLegales() {
  return (
    <div className="page-container">
      <div className="page-content">
        <h1 className="text-block page-title">Mentions légales</h1>
        <div className="text-block">
          <h2 className="home-h2">Présentation du site</h2>
          <p>
            Il est précisé ci-dessous les modalités d'utilisation du site
            https://duboiscecile.fr.
            <br />
            L’utilisation du site https://duboiscecile.fr implique l’acceptation
            pleine et entière des conditions générales d’utilisation ci-après
            décrites.
            <br />
            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour
            la confiance dans l'économie numérique, les différents intervenants
            et responsables de ce site sont :
            <br />
            <br />
            Propriétaire du site : Cécile Dubois - 292 route de la Maze - 07340
            Vinzieux - France
            <br />
            SIRET : 909 954 745 00016
            <br />
            duboiscecilepro@gmail.com
            <br />
            06 87 59 05 24
            <br />
            Créateur:/webmaster : Cécile Dubois
            <br />
            Responsable de la publication : Cécile Dubois
            <br />
            <br />
            Hébergeur: Planet Hoster - 4416 Louis B. Mayer, Laval (Québec) H7P
            0G1 -Canada
            <br /> https://www.planethoster.com/
            <br /> FR (Nº Vert): 0 805 080 426 <br />
            FR: +33 1 76 60 41 43
          </p>
        </div>
        <div className="text-block">
          <h2 className="home-h2">
            Description des services fournis et limitation contractuelles sur
            les données techniques
          </h2>
          <p>
            Le site https://duboiscecile.fr a pour objet de fournir une
            information concernant l’ensemble des activités de Cécile Dubois et
            s’efforce de fournir sur le site https://duboiscecile.fr des
            informations aussi précises que possible.
            <br />
            Toutefois, il ne pourra être tenu responsable des omissions, des
            inexactitudes et des carences dans la mise à jour, qu’elles soient
            de son fait ou du fait des tiers partenaires qui lui fournissent ces
            informations.
            <br />
            Tous les informations indiquées sur le site https://duboiscecile.fr
            sont données à titre indicatif, et sont susceptibles d’évoluer. Par
            ailleurs, les renseignements figurant sur le site
            https://duboiscecile.fr ne sont pas exhaustifs. Ils sont donnés sous
            réserve de modifications ayant été apportées depuis leur mise en
            ligne.
          </p>
        </div>
        <div className="text-block">
          <h2 className="home-h2">Propriété intellectuelle - Crédits</h2>
          <p>
            Les textes et certaines illustrations sont la propriété exclusive de
            Cécile Dubois.
            <br />
            Cécile Dubois détient détient les droits d’usage sur tous les autres
            éléments accessibles sur le site, notamment les textes, images,
            graphismes, logo, icônes, sons, logiciels.
            <br />
            Toute reproduction, distribution, modification, adaptation,
            retransmission ou publication, même partielle, des différents
            éléments du site est strictement interdite sans l’accord exprès par
            écrit de Cécile Dubois. Cette représentation ou reproduction, par
            quelque procédé que ce soit, constitue une contrefaçon sanctionnée
            par les articles L.335-2 et suivants du Code de la propriété
            intellectuelle, et pouvant engager la responsabilité civile et
            pénale du contrefacteur. En outre, Cécile Dubois se réserve le droit
            d'intenter une action en justice à l'encontre des contrefacteurs.
          </p>
        </div>
        <div className="text-block">
          <h2 className="home-h2">Données personnelles</h2>
          <p>
            De manière générale, l'utilisateur n'est pas tenu de communiquer ses
            données personnelles lorsqu'il visite le site
            https://duboiscecile.fr.
            <br />
            <br />
            Cependant, si l'utilisateur souhaite contacter Cécile Dubois dans le
            cadre d'une demande de prestation, il lui sera demandé de
            communiquer certaines données personnelles, notamment son nom, son
            adresse e-mail, son numéro de téléphone, ainsi que le contenu de sa
            demande, via le formulaire de contact. Ces données étant
            indispensables pour pouvoir traiter cette demande, le refus de les
            communiquer aura pour conséquence l'impossibilité, pour Cécile
            Dubois, de pouvoir répondre à la demande de l'utilisateur.
            <br />
            <br />
            Ces données collectées sont à usage exclusif de Cécile Dubois, dans
            le cadre de l'exécution de ses prestations, et ne sont en aucun cas
            transmises à des tiers.
            <br />
            <br />A l'heure actuelle, le site https://duboiscecile.fr n'utilise
            pas de cookies autres que les les cookies nécessaires au bon
            fonctionnement du site internet.
          </p>
        </div>
      </div>
    </div>
  );
}
